import BannerModel from '@/views/app/banner/model/BannerModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST } from '@/services/http';
import { PUT_S3_REQUEST } from '@/services/httpS3';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class BannerSourceListViewModel {
  constructor() {
    this.model = new BannerModel();
    this.isModify = false;
    this.valid = {
      startAt:{
        value: 'startAt',
        isValid:false,
        validAlertDesc: '공개기간을 입력해주세요',
      },
      endAt:{
        value: 'endAt',
        isValid:false,
        validAlertDesc: '공개기간을 입력해주세요',
      },
      target:{
        value: 'target',
        isValid:false,
        validAlertDesc: '타겟구분을 입력해주세요',
      },
      bannerImageUrl:{
        value: 'bannerImageUrl',
        valueType:'array',
        isValid:false,
        validAlertDesc: '베너이미지를 입력해주세요',
      },
      title:{
        value: 'title',
        isValid:false,
        validAlertDesc: '제목을 입력해주세요',
      },
      linkUrl:{
        value: 'linkUrl',
        isValid:false,
        validAlertDesc: '랜딩 URL을 입력해주세요',
      },
    }
  }

  init(id){
    if(id){
      this.isModify = true;
      this.getBannerSourceDetail(id);
    }
  }

  validatedResult(){
    return pageValidated(this.valid, this.model)
  }

  onClickDelete(){
    const requestDelete = () => {
      router.push({ name: 'APP_TERMS_LIST' });
      store.dispatch('commonToast/fetchToastStart', `약관이 삭제되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `약관을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  
  onCompleteFileSave(){
    if(this.isModify){
      this.putBannerSourceDetail();
    }else{
      this.postBannerSourceDetail();
    }
  }

  // 상세
  getBannerSourceDetail(id){
    const path = `${apiPath.BANNER_SOURCE_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // 등록
  postBannerSourceDetail(){
    const path = `${apiPath.BANNER_SOURCE_REGISTER}`;
    const data = this.model.getData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '베너소스가 등록되었습니다');
      router.push({ name: 'APP_BANNER_SOURCE_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // 수정
  putBannerSourceDetail(){
    const path = `${apiPath.BANNER_SOURCE_MODIFY.format(this.model.id)}`;
    const data = this.model.getData();
    PUT_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '베너소스가 수정되었습니다');
      router.push({ name: 'APP_BANNER_SOURCE_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}