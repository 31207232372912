<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>공개기간<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <DateTimePicker
              maxWidth="388px"
              :value.sync="viewModel.model.startAt"/>
              <span class="txt_tbl"> ~ </span>
            <DateTimePicker
              maxWidth="388px"
              :value.sync="viewModel.model.endAt"/>
            <p class="desc_info">💡 공개기간내라도 배너슬롯에 게시하지 않으면 앱에 노출되지 않아요</p>
          </td>
        </tr>
        <tr>
          <th>타겟구분<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              maxWidth="300px"
              :dataList="'user_target' | getSelectDataList"
              :value.sync="viewModel.model.target"/>
          </td>
        </tr>
        <tr>
          <th>베너이미지<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.bannerImageUrl"
              :noticeArray="['모바일 전용으로 용량을 줄여 등록해주세요']"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              sizeType="FIT"
              :saveTrigger="saveTrigger"
              :isSaved.sync="isSaved"/>
          </td>
          <!-- :size="[1174, 880]" -->
        </tr>
        <tr>
          <th>제목<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Textarea
              placeholder="2줄로 노출됩니다"
              :isCount="true"
              :showLine="2"
              :value.sync="viewModel.model.title"/>
          </td>
        </tr>
        <tr>
          <th>랜딩 URL<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              :value.sync="viewModel.model.linkUrl"/>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="APP_BANNER_SOURCE_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          :text="viewModel.isModify ? '수정' : '저장'"
          :disabled="isVaild"
          @onClickBtn="onClickComplete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import BannerSourceDetailViewModel from '@/views/app/banner/viewModel/BannerSourceDetailViewModel'
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name:'BannerSourceRegister',
  mixins:[FileSaveMixin],
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    DateTimePicker,
    Selectbox,
    FileUpload,
    Textarea,
    Input,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new BannerSourceDetailViewModel(),
    }
  },
  computed:{
    minDate(){
      return convertDateFormat(new Date(), 'YYYY-MM-DD')
    },
    isVaild(){
      return this.viewModel.validatedResult()
    }
  },
  methods:{
    onClickComplete(){
      if(this.isModify){
        const changedSrc = this.viewModel.model.imageList[0] ? this.viewModel.model.imageList[0].src : '';
        if(this.viewModel.model.objRaw.image != changedSrc){
          this.onSave();
        }else{
          this.$emit('onClickComplete',this.viewModel.model);
        }
      }else{
        this.onSave();
      }
    },
    onCompleteFileSave(){
      this.$emit('onClickComplete',this.viewModel.model);
    }
  },
}
</script>