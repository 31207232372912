import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'

export default class BannerModel {
  constructor(){
    this.id = '';

    this.startAt = '';
    this.endAt = '';
    this.postingDate = ['',''];
    this.bannerImageUrl = [];
    this.target = '';
    this.title = '';
    this.linkUrl = '';


    this.thumbnailImageUrl = '';
    this.status = '';
    this.badge = '';
    this.contents = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, thumbnailImageUrl, bannerImageUrl, target, status, title, badge, contents, linkUrl, startAt, endAt } = obj;
    this.id = id;
    this.thumbnailImageUrl = thumbnailImageUrl;
    if(bannerImageUrl){
      this.bannerImageUrl = [
        {
          src : bannerImageUrl,
        }
      ]
    }
    this.target = target;
    this.status = status;
    this.title = title;
    this.badge = badge;
    this.contents = contents;
    this.linkUrl = linkUrl;
    this.startAt = startAt;
    this.endAt = endAt;
  }
  getData(){
    let obj = {
      thumbnailImageUrl : this.thumbnailImageUrl,
      bannerImageUrl : this.bannerImageUrl.length > 0 ? this.bannerImageUrl[0].src : '',
      target : this.target,
      status : this.status || 'REGISTERED',
      title : this.title,
      badge : this.badge,
      contents : this.contents,
      linkUrl : this.linkUrl,
      startAt : convertDateFormat(this.startAt,'YYYY-MM-DDTHH:mm:ss.SSS'),
      endAt : convertDateFormat(this.endAt,'YYYY-MM-DDTHH:mm:ss.SSS'),
    }
    return obj;
  }
}